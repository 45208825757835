/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type GetConsumerAddressInput = {
  consumerId: string,
  addressId: string,
  businessId: string,
};

export type Address = {
  __typename: "Address",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: AddressStatus,
  street: string,
  street2?: string | null,
  city: string,
  postalCode: string,
  country: CountryCode,
  lat: number,
  long: number,
  isDefault: boolean,
  title?: string | null,
  stateOrProvince?: StateOrProvince | null,
  instructions?: string | null,
};

export enum AddressStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum CountryCode {
  US = "US",
}


export enum StateOrProvince {
  AK = "AK",
  AL = "AL",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}


export type CreateConsumerAddressInput = {
  businessId: string,
  consumerId: string,
  street: string,
  street2?: string | null,
  city: string,
  postalCode: string,
  country: CountryCode,
  lat?: number | null,
  long?: number | null,
  stateOrProvince?: StateOrProvince | null,
  isDefault?: boolean | null,
  instructions?: string | null,
};

export type AddressDefaultPayload = {
  __typename: "AddressDefaultPayload",
  address?: Address | null,
};

export type UpdateConsumerAddressInput = {
  consumerId: string,
  businessId: string,
  addressId: string,
  isDefault?: boolean | null,
  instructions?: string | null,
};

export type ArchiveConsumerAddressInput = {
  addressId: string,
  consumerId: string,
  businessId: string,
};

export type GetBusinessInput = {
  businessId?: string | null,
  url?: string | null,
  statuses?: Array< BusinessStatus > | null,
};

export enum BusinessStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
  ARCHIVED = "ARCHIVED",
}


export type Business = {
  __typename: "Business",
  id: string,
  createdAt: string,
  updatedAt: string,
  acceptedTermsAt: string,
  status: BusinessStatus,
  type: BusinessType,
  level: BusinessLevelType,
  email: string,
  phoneNumber: string,
  subdomain: string,
  address: Address,
  balance: BusinessBalance,
  metrics: BusinessMetrics,
  currency: Currency,
  producer: Producer,
  verificationDetails: VerificationDetails,
  taxRates:  Array<TaxRate >,
  metadata: BusinessMetadata,
  settings: BusinessSettings,
  structure?: BusinessStructure | null,
  termSheetLocation?: string | null,
  cognito?: CognitoIdentity | null,
  notificationNumber?: string | null,
  customDomain?: string | null,
  emailSesVerified?: boolean | null,
  noReplyEmail?: string | null,
  noReplyEmailSesVerified?: boolean | null,
  smsPhoneNumber?: string | null,
  bankAccount?: BankAccount | null,
  fees: BusinessFees,
  sms?: BusinessSms | null,
  couponRedemptions:  Array<CouponRedemption >,
  balanceTransactions?: BalanceTransactionConnection | null,
  consumers?: ConsumerConnection | null,
  coupons?: CouponConnection | null,
  giftCards?: GiftCardConnection | null,
  locations?: LocationConnection | null,
  orders?: OrderConnection | null,
  recurringOrders?: RecurringOrderConnection | null,
  plans?: PlanConnection | null,
  products?: ProductConnection | null,
  subscriptionInvoices?: SubscriptionInvoiceConnection | null,
  smsPhoneNumbers?: SmsPhoneNumberConnection | null,
  consumerGroups?: ConsumerGroupConnection | null,
};

export enum BusinessType {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
  NON_PROFIT = "NON_PROFIT",
}


export enum BusinessLevelType {
  STANDARD = "STANDARD",
  BUSINESS = "BUSINESS",
  PREMIUM = "PREMIUM",
  ENTERPRISE = "ENTERPRISE",
}


export type BusinessBalance = {
  __typename: "BusinessBalance",
  available: MonetaryValue,
  pending: MonetaryValue,
  comparison: number,
};

export type MonetaryValue = {
  __typename: "MonetaryValue",
  amount: number,
  currency: Currency,
};

export type Currency = {
  __typename: "Currency",
  abbreviation: CurrencyAbbreviation,
  symbol: string,
};

export enum CurrencyAbbreviation {
  USD = "USD",
}


export type BusinessMetrics = {
  __typename: "BusinessMetrics",
  subscription: BusinessSubscriptionMetrics,
  order: BusinessOrderMetrics,
};

export type BusinessSubscriptionMetrics = {
  __typename: "BusinessSubscriptionMetrics",
  businessCancellationCount: Metric,
  consumerCancellationCount: Metric,
  paymentFailedCount: Metric,
  paymentSucceededCount: Metric,
  subscriptionPurchaseCount: Metric,
  subscriptionRevenue: Metric,
};

export type Metric = {
  __typename: "Metric",
  timestamps: Array< string >,
  values: Array< number >,
};

export type BusinessOrderMetrics = {
  __typename: "BusinessOrderMetrics",
  businessCancellationCount: Metric,
  consumerCancellationCount: Metric,
  orderPurchaseCount: Metric,
  orderRevenue: Metric,
};

export type Producer = {
  __typename: "Producer",
  id: string,
  createdAt: string,
  updatedAt: string,
  acceptedTermsAt: string,
  status: UserStatus,
  email: string,
  phoneNumber?: string | null,
  firstName: string,
  lastName: string,
  roles: Array< UserRole >,
  credit: MonetaryValue,
  avatar?: string | null,
  business?: Business | null,
  card?: Card | null,
  invoices?: InvoiceConnection | null,
};

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
  BLOCKED = "BLOCKED",
}


export enum UserRole {
  PRODUCER = "PRODUCER",
  CONSUMER = "CONSUMER",
  GUEST_CONSUMER = "GUEST_CONSUMER",
  ADMIN = "ADMIN",
}


export type Card = {
  __typename: "Card",
  id: string,
  lastFour: string,
  brand: string,
  expMonth: number,
  expYear: number,
};

export type InvoiceConnection = {
  __typename: "InvoiceConnection",
  edges:  Array<InvoiceEdge >,
  pageInfo: PageInfo,
};

export type InvoiceEdge = {
  __typename: "InvoiceEdge",
  cursor: string,
  node: Invoice,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  createdAt: string,
  status: InvoiceStatus,
  amountPaid: MonetaryValue,
  total: MonetaryValue,
  interval: DateRange,
  descriptor: string,
  card?: Card | null,
};

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  PAID = "PAID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  VOID = "VOID",
}


export type DateRange = {
  __typename: "DateRange",
  start: string,
  end: string,
};

export type PageInfo = {
  __typename: "PageInfo",
  startCursor?: string | null,
  endCursor?: string | null,
  hasPreviousPage?: boolean | null,
  hasNextPage?: boolean | null,
};

export type VerificationDetails = {
  __typename: "VerificationDetails",
  status: VerificationStatus,
  collectNow: boolean,
  collectSoon: boolean,
  collectEventually: boolean,
};

export enum VerificationStatus {
  PENDING = "PENDING",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
}


export type TaxRate = {
  __typename: "TaxRate",
  id: string,
  status?: TaxRateStatus | null,
  type: TaxType,
  percentage: number,
  category: TaxCategory,
  state: StateOrProvince,
  country: CountryCode,
};

export enum TaxRateStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum TaxType {
  SALES_TAX = "SALES_TAX",
}


export enum TaxCategory {
  PREPARED_FOODS = "PREPARED_FOODS",
}


export type BusinessMetadata = {
  __typename: "BusinessMetadata",
  id: string,
  createdAt: string,
  updatedAt: string,
  title: string,
  subdomain: string,
  promotePlansText: string,
  showPromotePlans: boolean,
  headline: Headline,
  social: Social,
  supportPhoneNumber: string,
  supportEmail: string,
  productAllergens: Array< string >,
  productCategories: Array< string >,
  productTags: Array< string >,
  howItWorks:  Array<HowItWorksItem >,
  showHowItWorks: boolean,
  testimonials:  Array<Testimonial >,
  showTestimonials: boolean,
  faqs:  Array<Faq >,
  showFaqs: boolean,
  featuredProducts:  Array<Product >,
  showFeaturedProducts: boolean,
  aboutUs: AboutUs,
  showAboutUs: boolean,
  teamMemberProfiles:  Array<TeamMemberProfile >,
  showTeamMemberProfiles: boolean,
  giftCard: GiftCardSetting,
  showGiftCard: boolean,
  pathSegments: Array< string >,
  colorTheme: ColorTheme,
  avatarImage?: string | null,
  coverImage?: string | null,
  toolbarImage?: string | null,
  customDomain?: string | null,
  supportWidget?: string | null,
  banner?: string | null,
  promotedSchedule?: Schedule | null,
  metaPixelId?: string | null,
  marketing?: BusinessMarketing | null,
};

export type Headline = {
  __typename: "Headline",
  primary: string,
  secondary: string,
};

export type Social = {
  __typename: "Social",
  facebook?: string | null,
  instagram?: string | null,
  linkedin?: string | null,
  twitter?: string | null,
};

export type HowItWorksItem = {
  __typename: "HowItWorksItem",
  title: string,
  subtitle: string,
  icon?: string | null,
};

export type Testimonial = {
  __typename: "Testimonial",
  id: string,
  createdAt: string,
  updatedAt: string,
  name: string,
  city: string,
  message: string,
};

export type Faq = {
  __typename: "Faq",
  id: string,
  createdAt: string,
  updatedAt: string,
  question: string,
  answer: string,
};

export type Product = {
  __typename: "Product",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: ProductStatus,
  title: string,
  price: MonetaryValue,
  description?: string | null,
  images: Array< string >,
  categories: Array< string >,
  tags: Array< string >,
  allergens: Array< string >,
  ingredients:  Array<Ingredient >,
  nutrition?: Nutrition | null,
  configurations:  Array<ProductConfiguration >,
  offers?: OfferConnection | null,
  taxExempt: boolean,
};

export enum ProductStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type Ingredient = {
  __typename: "Ingredient",
  name?: string | null,
  value?: number | null,
  unit?: IngredientUnit | null,
};

export enum IngredientUnit {
  POUND = "POUND",
  OUNCE = "OUNCE",
  MILLIGRAM = "MILLIGRAM",
  GRAM = "GRAM",
  TEASPOON = "TEASPOON",
  TABLESPOON = "TABLESPOON",
  FLUID_OUNCE = "FLUID_OUNCE",
  CUP = "CUP",
  PINT = "PINT",
  QUART = "QUART",
  GALLON = "GALLON",
  MILLILITER = "MILLILITER",
}


export type Nutrition = {
  __typename: "Nutrition",
  calorie?: number | null,
  carbohydrate?: number | null,
  protein?: number | null,
  fat?: number | null,
};

export type ProductConfiguration = {
  __typename: "ProductConfiguration",
  id: string,
  title: string,
  options:  Array<ProductConfigurationOption >,
  visible: boolean,
  minSelect: number,
  maxSelect: number,
};

export type ProductConfigurationOption = {
  __typename: "ProductConfigurationOption",
  id: string,
  description: string,
  price: MonetaryValue,
  visible: boolean,
};

export type OfferConnection = {
  __typename: "OfferConnection",
  edges:  Array<OfferEdge >,
  pageInfo: PageInfo,
};

export type OfferEdge = {
  __typename: "OfferEdge",
  cursor: string,
  node: Offer,
};

export type Offer = {
  __typename: "Offer",
  id: string,
  createdAt: string,
  updatedAt: string,
  status?: OfferStatus | null,
  productId: string,
  scheduleId: string,
  product: Product,
  schedule: Schedule,
  soldQuantity: number,
  maxQuantity: number,
  price: MonetaryValue,
  configurations:  Array<ProductConfiguration >,
};

export enum OfferStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type Schedule = {
  __typename: "Schedule",
  id: string,
  status: ScheduleStatus,
  createdAt: string,
  updatedAt: string,
  type: TransportationType,
  orderReadyStart: string,
  orderReadyEnd: string,
  orderCutoff: string,
  promoted: boolean,
  location: Location,
  pickupAddress?: Address | null,
  maxQuantity?: number | null,
  publishAt?: string | null,
  message?: string | null,
  title?: string | null,
  offers?: OfferConnection | null,
  orders?: OrderConnection | null,
};

export enum ScheduleStatus {
  DRAFT = "DRAFT",
  UPCOMING = "UPCOMING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum TransportationType {
  DELIVERY = "DELIVERY",
  PICK_UP = "PICK_UP",
}


export type Location = {
  __typename: "Location",
  id: string,
  createdAt: string,
  updatedAt: string,
  acceptedTermsAt: string,
  status: LocationStatus,
  pathSegment: string,
  title: string,
  metrics: LocationMetrics,
  revenue: LocationRevenue,
  supportEmail: string,
  supportPhoneNumber: string,
  description?: string | null,
  awayMessage?: string | null,
  website?: string | null,
  fulfillment: LocationFulfillment,
  coupons:  Array<Coupon >,
  plans:  Array<Plan >,
  deliveries?: DeliveryConnection | null,
  offers?: OfferConnection | null,
  orders?: OrderConnection | null,
  recurringOrders?: RecurringOrderConnection | null,
  pickupAddresses?: AddressConnection | null,
  schedules?: ScheduleConnection | null,
  transactions?: TransactionConnection | null,
  unfulfilledOrders?: UnfulfilledOrderConnection | null,
  couponRedemptions:  Array<CouponRedemption >,
  cookingReport: LocationCookingReport,
};

export enum LocationStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
  BLOCKED = "BLOCKED",
}


export type LocationMetrics = {
  __typename: "LocationMetrics",
  order: LocationOrderMetrics,
};

export type LocationOrderMetrics = {
  __typename: "LocationOrderMetrics",
  locationCancellationCount: Metric,
  consumerCancellationCount: Metric,
  orderPurchaseCount: Metric,
  orderRevenue: Metric,
};

export type LocationRevenue = {
  __typename: "LocationRevenue",
  week: MonetaryValue,
  month: MonetaryValue,
  ytd: MonetaryValue,
};

export type LocationFulfillment = {
  __typename: "LocationFulfillment",
  daily:  Array<DailyLocationFulfillment >,
};

export type DailyLocationFulfillment = {
  __typename: "DailyLocationFulfillment",
  date: string,
  purchaseCount: number,
  completeCount: number,
  cancelCount: number,
};

export type Coupon = {
  __typename: "Coupon",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: CouponStatus,
  type: CouponType,
  name: string,
  duration: CouponDuration,
  minimumTotal: MonetaryValue,
  locationIds: Array< string >,
  locations:  Array<Location >,
  audience: Audience,
  redeemCount: number,
  amountOff?: MonetaryValue | null,
  percentOff?: number | null,
  description?: string | null,
  dateRange?: DateRange | null,
  orders?: OrderConnection | null,
};

export enum CouponStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum CouponType {
  AMOUNT_OFF = "AMOUNT_OFF",
  PERCENT_OFF = "PERCENT_OFF",
  FREE_DELIVERY = "FREE_DELIVERY",
}


export enum CouponDuration {
  NEW = "NEW",
  ONCE = "ONCE",
  FOREVER = "FOREVER",
}


export enum Audience {
  ALL_CUSTOMERS = "ALL_CUSTOMERS",
  NEW_CUSTOMERS = "NEW_CUSTOMERS",
}


export type OrderConnection = {
  __typename: "OrderConnection",
  total: number,
  edges:  Array<OrderEdge >,
  pageInfo: PageInfo,
};

export type OrderEdge = {
  __typename: "OrderEdge",
  cursor: string,
  node: Order,
};

export type Order = {
  __typename: "Order",
  id: string,
  checkoutId: string,
  locationId: string,
  createdAt: string,
  updatedAt: string,
  transactionType: TransactionType,
  orderStatus: OrderStatus,
  orderType: TransportationType,
  consumer: Consumer,
  location: Location,
  schedule: Schedule,
  orderItems:  Array<OrderItem >,
  creditBalanceSnapshot: MonetaryValue,
  refunds:  Array<Refund >,
  number?: string | null,
  couponName?: string | null,
  planTitle?: string | null,
  cardLastFour?: string | null,
  cardBrand?: string | null,
  cardId?: string | null,
  consumerAddress?: Address | null,
  pickupAddress?: Address | null,
  addressId?: string | null,
  delivery?: Delivery | null,
  deliveryId?: string | null,
  cost?: OrderCost | null,
  note?: string | null,
  instructions?: string | null,
};

export enum TransactionType {
  ORDER = "ORDER",
  RECURRING_ORDER = "RECURRING_ORDER",
  SUBSCRIPTION_INVOICE = "SUBSCRIPTION_INVOICE",
  GIFT_CARD = "GIFT_CARD",
  PAYOUT = "PAYOUT",
  REFUND = "REFUND",
  CREDIT = "CREDIT",
}


export enum OrderStatus {
  CART = "CART",
  CART_EXPIRED = "CART_EXPIRED",
  IN_REVIEW = "IN_REVIEW",
  IN_REVIEW_EXPIRED = "IN_REVIEW_EXPIRED",
  PURCHASED = "PURCHASED",
  COMPLETED = "COMPLETED",
  CONSUMER_CANCELED = "CONSUMER_CANCELED",
  LOCATION_CANCELED = "LOCATION_CANCELED",
}


export type Consumer = {
  __typename: "Consumer",
  id: string,
  customerId: string,
  createdAt: string,
  updatedAt: string,
  acceptedTermsAt: string,
  status: UserStatus,
  role: UserRole,
  email: string,
  firstName: string,
  lastName: string,
  creditBalance: MonetaryValue,
  totalSpent: MonetaryValue,
  allergens: Array< string >,
  businessNote?: string | null,
  phoneNumber?: string | null,
  avatar?: string | null,
  smsEnabled: boolean,
  defaultAddress?: Address | null,
  subscriptions?: SubscriptionConnection | null,
  addresses?: AddressConnection | null,
  cards?: CardConnection | null,
  giftCards?: GiftCardConnection | null,
  subscriptionInvoices?: SubscriptionInvoiceConnection | null,
  orders?: OrderConnection | null,
  recurringOrders?: RecurringOrderConnection | null,
  transactions?: TransactionConnection | null,
  latestCart?: Cart | null,
  smsConversation: SmsConversation,
};

export type SubscriptionConnection = {
  __typename: "SubscriptionConnection",
  total: number,
  edges:  Array<SubscriptionEdge >,
  pageInfo: PageInfo,
};

export type SubscriptionEdge = {
  __typename: "SubscriptionEdge",
  cursor: string,
  node: Subscription,
};

export type Subscription = {
  __typename: "Subscription",
  id: string,
  status: SubscriptionStatus,
  createdAt: string,
  updatedAt: string,
  collection: SubscriptionCollectionStatus,
  business: Business,
  consumer: Consumer,
  plan: Plan,
  name: string,
  email: string,
  currentPeriodStart: string,
  currentPeriodEnd: string,
  consumerId: string,
  customerId: string,
  fulfillment:  Array<PlanFulfillment >,
  resumesAt?: string | null,
  card?: Card | null,
  subscriptionInvoices?: SubscriptionInvoiceConnection | null,
  recurringOrders?: RecurringOrderConnection | null,
};

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID",
}


export enum SubscriptionCollectionStatus {
  PAUSE = "PAUSE",
  COLLECT = "COLLECT",
}


export type Plan = {
  __typename: "Plan",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: PlanStatus,
  title: string,
  interval: PlanInterval,
  price: MonetaryValue,
  images: Array< string >,
  revenue: PlanRevenue,
  statistic: PlanStatistic,
  transportationType: TransportationType,
  chargeDay: DayOfWeek,
  location: Location,
  fulfillment:  Array<PlanFulfillment >,
  allowSelection: boolean,
  allowNewSubscribers: boolean,
  description?: string | null,
  products?: ProductConnection | null,
  subscriptions?: SubscriptionConnection | null,
  pickupAddress?: Address | null,
};

export enum PlanStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export enum PlanInterval {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}


export type PlanRevenue = {
  __typename: "PlanRevenue",
  week: MonetaryValue,
  month: MonetaryValue,
  ytd: MonetaryValue,
};

export type PlanStatistic = {
  __typename: "PlanStatistic",
  activeSubscriptionCount: number,
  pausedSubscriptionCount: number,
  pastDueSubscriptionCount: number,
  unpaidSubscriptionCount: number,
  cancelledSubscriptionCount: number,
};

export enum DayOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}


export type PlanFulfillment = {
  __typename: "PlanFulfillment",
  product: Product,
  day: DayOfWeek,
  quantity: number,
};

export type ProductConnection = {
  __typename: "ProductConnection",
  total: number,
  edges:  Array<ProductEdge >,
  pageInfo: PageInfo,
};

export type ProductEdge = {
  __typename: "ProductEdge",
  cursor: string,
  node: Product,
};

export type SubscriptionInvoiceConnection = {
  __typename: "SubscriptionInvoiceConnection",
  total: number,
  edges:  Array<SubscriptionInvoiceEdge >,
  pageInfo: PageInfo,
};

export type SubscriptionInvoiceEdge = {
  __typename: "SubscriptionInvoiceEdge",
  cursor: string,
  node: SubscriptionInvoice,
};

export type SubscriptionInvoice = {
  __typename: "SubscriptionInvoice",
  id: string,
  createdAt: string,
  updatedAt: string,
  transactionType: TransactionType,
  invoiceStatus: InvoiceStatus,
  consumerFirstName: string,
  consumerLastName: string,
  consumerEmail: string,
  period: DateRange,
  creditBalanceSnapshot: MonetaryValue,
  creditTotal?: MonetaryValue | null,
  refunds:  Array<Refund >,
  retryAttemptCount?: number | null,
  nextPaymentAttempt?: string | null,
  paymentStatus?: PaymentStatus | null,
  cardLastFour?: string | null,
  cardBrand?: string | null,
  planTitle?: string | null,
  cost?: SubscriptionInvoiceCost | null,
  number?: string | null,
  subscription?: Subscription | null,
  recurringOrders?: RecurringOrderConnection | null,
};

export type Refund = {
  __typename: "Refund",
  id: string,
  createdAt: string,
  updatedAt: string,
  refundStatus: RefundStatus,
  transactionType: TransactionType,
  creditBalanceSnapshot: MonetaryValue,
  number?: string | null,
  description?: string | null,
  order?: Order | null,
  subscriptionInvoice?: SubscriptionInvoice | null,
  giftCard?: GiftCard | null,
  cost?: RefundCost | null,
};

export enum RefundStatus {
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
}


export type GiftCard = {
  __typename: "GiftCard",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: GiftCardStatus,
  transactionType: TransactionType,
  redemptionType: GiftCardRedemptionType,
  issuerId: string,
  issuer: Consumer,
  token: string,
  recipientEmail: string,
  recipientName: string,
  card: Card,
  creditBalanceSnapshot: MonetaryValue,
  number?: string | null,
  cost?: GiftCardCost | null,
  redeemedAt?: string | null,
  description?: string | null,
  recipientId?: string | null,
  refund?: Refund | null,
};

export enum GiftCardStatus {
  PURCHASED = "PURCHASED",
  REDEEMED = "REDEEMED",
  REFUNDED = "REFUNDED",
}


export enum GiftCardRedemptionType {
  CREDIT = "CREDIT",
}


export type GiftCardCost = {
  __typename: "GiftCardCost",
  price?: MonetaryValue | null,
  subtotal?: MonetaryValue | null,
  estimatedTax?: MonetaryValue | null,
  creditAmount?: MonetaryValue | null,
  netTotal?: MonetaryValue | null,
  total?: MonetaryValue | null,
};

export type RefundCost = {
  __typename: "RefundCost",
  paymentTotal?: MonetaryValue | null,
  serviceFee?: MonetaryValue | null,
};

export enum PaymentStatus {
  SUCCEEDED = "SUCCEEDED",
  PENDING = "PENDING",
  FAILED = "FAILED",
}


export type SubscriptionInvoiceCost = {
  __typename: "SubscriptionInvoiceCost",
  subtotal?: MonetaryValue | null,
  estimatedTax?: MonetaryValue | null,
  serviceFee?: MonetaryValue | null,
  paymentTotal?: MonetaryValue | null,
  total?: MonetaryValue | null,
};

export type RecurringOrderConnection = {
  __typename: "RecurringOrderConnection",
  total: number,
  edges:  Array<RecurringOrderEdge >,
  pageInfo: PageInfo,
};

export type RecurringOrderEdge = {
  __typename: "RecurringOrderEdge",
  cursor: string,
  node: RecurringOrder,
};

export type RecurringOrder = {
  __typename: "RecurringOrder",
  id: string,
  locationId: string,
  createdAt: string,
  updatedAt: string,
  fulfillOn: string,
  transactionType: TransactionType,
  recurringOrderStatus: RecurringOrderStatus,
  orderType: TransportationType,
  consumer: Consumer,
  location: Location,
  orderItems:  Array<RecurringOrderItem >,
  subscription: Subscription,
  creditBalanceSnapshot: MonetaryValue,
  number?: string | null,
  subscriptionInvoice?: SubscriptionInvoice | null,
  planTitle?: string | null,
  consumerAddress?: Address | null,
  pickupAddress?: Address | null,
  cost?: OrderCost | null,
  instructions?: string | null,
};

export enum RecurringOrderStatus {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  BUSINESS_CANCELED = "BUSINESS_CANCELED",
  CONSUMER_CANCELED = "CONSUMER_CANCELED",
  COMPLETED = "COMPLETED",
}


export type RecurringOrderItem = {
  __typename: "RecurringOrderItem",
  product: Product,
  quantity: number,
  title: string,
  price: MonetaryValue,
};

export type OrderCost = {
  __typename: "OrderCost",
  subtotal?: MonetaryValue | null,
  tip?: MonetaryValue | null,
  estimatedTax?: MonetaryValue | null,
  serviceFee?: MonetaryValue | null,
  deliveryFee?: MonetaryValue | null,
  couponAmount?: MonetaryValue | null,
  creditAmount?: MonetaryValue | null,
  netTotal?: MonetaryValue | null,
  total?: MonetaryValue | null,
};

export type AddressConnection = {
  __typename: "AddressConnection",
  total: number,
  edges:  Array<AddressEdge >,
  pageInfo: PageInfo,
};

export type AddressEdge = {
  __typename: "AddressEdge",
  cursor: string,
  node: Address,
};

export type CardConnection = {
  __typename: "CardConnection",
  total: number,
  edges:  Array<CardEdge >,
  pageInfo: PageInfo,
};

export type CardEdge = {
  __typename: "CardEdge",
  cursor: string,
  node: Card,
};

export type GiftCardConnection = {
  __typename: "GiftCardConnection",
  total: number,
  edges:  Array<GiftCardEdge >,
  pageInfo: PageInfo,
};

export type GiftCardEdge = {
  __typename: "GiftCardEdge",
  cursor: string,
  node: GiftCard,
};

export type TransactionConnection = {
  __typename: "TransactionConnection",
  total: number,
  edges:  Array<TransactionEdge >,
  pageInfo: PageInfo,
};

export type TransactionEdge = {
  __typename: "TransactionEdge",
  cursor: string,
  node: Transaction,
};

export type Transaction = Order | RecurringOrder | SubscriptionInvoice | Credit | Refund | GiftCard


export type Credit = {
  __typename: "Credit",
  id: string,
  createdAt: string,
  creditStatus: CreditStatus,
  transactionType: TransactionType,
  creditBalanceSnapshot: MonetaryValue,
  description?: string | null,
  cost?: CreditCost | null,
};

export enum CreditStatus {
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}


export type CreditCost = {
  __typename: "CreditCost",
  creditAmount?: MonetaryValue | null,
};

export type Cart = {
  __typename: "Cart",
  id: string,
  createdAt: string,
  updatedAt: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
  location: Location,
  cartSchedules:  Array<CartSchedule >,
  subtotal: MonetaryValue,
  activeScheduleId?: string | null,
  addressId?: string | null,
  addressDisplay?: string | null,
  postalCode?: string | null,
  type?: TransportationType | null,
};

export type CartSchedule = {
  __typename: "CartSchedule",
  scheduleId: string,
  dateRange: DateRange,
  cutoff: string,
  cartOffers:  Array<CartOffer >,
  subtotal: MonetaryValue,
  title?: string | null,
};

export type CartOffer = {
  __typename: "CartOffer",
  id: string,
  offerId: string,
  title: string,
  quantity: number,
  unitPrice: MonetaryValue,
  configurationSelections:  Array<OfferConfigurationSelection >,
  note?: string | null,
};

export type OfferConfigurationSelection = {
  __typename: "OfferConfigurationSelection",
  titleId: string,
  title: string,
  selections:  Array<OfferConfigurationOptionSelection >,
};

export type OfferConfigurationOptionSelection = {
  __typename: "OfferConfigurationOptionSelection",
  optionId: string,
  option: string,
  price: MonetaryValue,
};

export type SmsConversation = {
  __typename: "SmsConversation",
  id: string,
  createdAt: string,
  updatedAt: string,
  business: Business,
  consumer: Consumer,
  hasUnread: boolean,
};

export type OrderItem = {
  __typename: "OrderItem",
  offer: Offer,
  quantity: number,
  price: MonetaryValue,
  title: string,
  configurationSelections:  Array<OfferConfigurationSelection >,
  note?: string | null,
};

export type Delivery = {
  __typename: "Delivery",
  id: string,
  createdAt: string,
  updatedAt: string,
  status: DeliveryStatus,
  minimumTotal: MonetaryValue,
  fee: MonetaryValue,
  postalCodes: Array< string >,
};

export enum DeliveryStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}


export type DeliveryConnection = {
  __typename: "DeliveryConnection",
  total: number,
  edges:  Array<DeliveryEdge >,
  pageInfo: PageInfo,
};

export type DeliveryEdge = {
  __typename: "DeliveryEdge",
  cursor: string,
  node: Delivery,
};

export type ScheduleConnection = {
  __typename: "ScheduleConnection",
  edges:  Array<ScheduleEdge >,
  pageInfo: PageInfo,
};

export type ScheduleEdge = {
  __typename: "ScheduleEdge",
  cursor: string,
  node: Schedule,
};

export type UnfulfilledOrderConnection = {
  __typename: "UnfulfilledOrderConnection",
  total: number,
  edges:  Array<UnfulfilledOrderEdge >,
  pageInfo: PageInfo,
};

export type UnfulfilledOrderEdge = {
  __typename: "UnfulfilledOrderEdge",
  cursor: string,
  node: UnfulfilledOrder,
};

export type UnfulfilledOrder = Order | RecurringOrder


export type CouponRedemption = {
  __typename: "CouponRedemption",
  couponId: string,
  total: number,
  week: number,
  month: number,
  ytd: number,
};

export type LocationCookingReport = {
  __typename: "LocationCookingReport",
  items:  Array<CookingReportItem >,
};

export type CookingReportItem = {
  __typename: "CookingReportItem",
  product: Product,
  description: string,
  quantity: number,
  subItems:  Array<CookingReportSubItem >,
};

export type CookingReportSubItem = {
  __typename: "CookingReportSubItem",
  quantity: number,
  configurationSelections:  Array<OfferConfigurationSelection >,
  note?: string | null,
  orders:  Array<Transaction >,
};

export type AboutUs = {
  __typename: "AboutUs",
  origin: string,
  mission: string,
};

export type TeamMemberProfile = {
  __typename: "TeamMemberProfile",
  id: string,
  name: string,
  title: string,
  bio: string,
  position: number,
  picture?: string | null,
};

export type GiftCardSetting = {
  __typename: "GiftCardSetting",
  minPrice: MonetaryValue,
  maxPrice: MonetaryValue,
};

export enum ColorTheme {
  BLUE = "BLUE",
  CYAN = "CYAN",
  GRAY = "GRAY",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  PINK = "PINK",
  PURPLE = "PURPLE",
  TEAL = "TEAL",
  YELLOW = "YELLOW",
  DEFAULT = "DEFAULT",
}


export type BusinessMarketing = {
  __typename: "BusinessMarketing",
  provider: MarketingProvider,
  enabled: boolean,
  listId?: string | null,
  integrationScript?: string | null,
  hostedSignUpUrl?: string | null,
};

export enum MarketingProvider {
  MAILCHIMP = "MAILCHIMP",
}


export type BusinessSettings = {
  __typename: "BusinessSettings",
  subscriptionAllowPause: boolean,
  subscriptionAllowCancel: boolean,
  orderAllowCancel: boolean,
  orderCreditOnlyRefund: boolean,
};

export enum BusinessStructure {
  MULTI_MEMBER_LLC = "MULTI_MEMBER_LLC",
  SINGLE_MEMBER_LLC = "SINGLE_MEMBER_LLC",
  PRIVATE_CORPORATION = "PRIVATE_CORPORATION",
  PRIVATE_PARTNERSHIP = "PRIVATE_PARTNERSHIP",
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  UNINCORPORATED_ASSOCIATION = "UNINCORPORATED_ASSOCIATION",
  INCORPORATED_NON_PROFIT = "INCORPORATED_NON_PROFIT",
  UNINCORPORATED_NON_PROFIT = "UNINCORPORATED_NON_PROFIT",
}


export type CognitoIdentity = {
  __typename: "CognitoIdentity",
  identityPoolId: string,
  userPoolId: string,
  userPoolClientId: string,
};

export type BankAccount = {
  __typename: "BankAccount",
  id: string,
  status: BankAccountStatus,
  type: AccountHolderType,
  accountHolderName: string,
  bankName: string,
  lastFour: string,
  routingNumber: string,
};

export enum BankAccountStatus {
  NEW = "NEW",
  VALIDATED = "VALIDATED",
  VERIFIED = "VERIFIED",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  ERRORED = "ERRORED",
}


export enum AccountHolderType {
  COMPANY = "COMPANY",
  INDIVIDUAL = "INDIVIDUAL",
}


export type BusinessFees = {
  __typename: "BusinessFees",
  businessSubscriptionFee: number,
  businessTransactionFee: number,
  consumerSubscriptionFee: number,
  consumerTransactionFee: number,
};

export type BusinessSms = {
  __typename: "BusinessSms",
  sandbox: boolean,
  phoneNumber: string,
  sid: string,
  status: BusinessSmsStatus,
};

export enum BusinessSmsStatus {
  PENDING = "PENDING",
  IN_REVIEW = "IN_REVIEW",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}


export type BalanceTransactionConnection = {
  __typename: "BalanceTransactionConnection",
  total: number,
  edges:  Array<BalanceTransactionEdge >,
  pageInfo: PageInfo,
};

export type BalanceTransactionEdge = {
  __typename: "BalanceTransactionEdge",
  cursor: string,
  node: BalanceTransaction,
};

export type BalanceTransaction = {
  __typename: "BalanceTransaction",
  id: string,
  createdAt: string,
  updatedAt: string,
  availableOn: string,
  status: BalanceTransactionStatus,
  type: BalanceTransactionType,
  reportingCategory: BalanceTransactionReportingCategory,
  net: MonetaryValue,
  fee: MonetaryValue,
  amount: MonetaryValue,
  source: BalanceTransactionSource,
};

export enum BalanceTransactionStatus {
  AVAILABLE = "AVAILABLE",
  PENDING = "PENDING",
}


export enum BalanceTransactionType {
  CHARGE = "CHARGE",
  PAYMENT = "PAYMENT",
  PAYMENT_REFUND = "PAYMENT_REFUND",
  PAYOUT = "PAYOUT",
  PAYOUT_CANCEL = "PAYOUT_CANCEL",
  PAYOUT_FAILURE = "PAYOUT_FAILURE",
  RECIPIENT_TRANSFER = "RECIPIENT_TRANSFER",
  RECIPIENT_TRANSFER_CANCEL = "RECIPIENT_TRANSFER_CANCEL",
  RECIPIENT_TRANSFER_FAILURE = "RECIPIENT_TRANSFER_FAILURE",
  REFUND = "REFUND",
  TRANSFER = "TRANSFER",
  TRANSFER_CANCEL = "TRANSFER_CANCEL",
  TRANSFER_FAILURE = "TRANSFER_FAILURE",
}


export enum BalanceTransactionReportingCategory {
  CHARGE = "CHARGE",
  REFUND = "REFUND",
  PAYOUT = "PAYOUT",
  PAYOUT_REVERSAL = "PAYOUT_REVERSAL",
  TRANSFER = "TRANSFER",
  TRANSFER_REVERSAL = "TRANSFER_REVERSAL",
}


export type BalanceTransactionSource = {
  __typename: "BalanceTransactionSource",
  id: string,
  numbers: Array< string >,
  type?: BalanceTransactionSourceType | null,
};

export enum BalanceTransactionSourceType {
  GIFT_CARD = "GIFT_CARD",
  REFUND_ORDER = "REFUND_ORDER",
  REFUND_SUBSCRIPTION_INVOICE = "REFUND_SUBSCRIPTION_INVOICE",
  PAYOUT = "PAYOUT",
  ORDER = "ORDER",
  SUBSCRIPTION_INVOICE = "SUBSCRIPTION_INVOICE",
}


export type ConsumerConnection = {
  __typename: "ConsumerConnection",
  total: number,
  edges:  Array<ConsumerEdge >,
  pageInfo: PageInfo,
};

export type ConsumerEdge = {
  __typename: "ConsumerEdge",
  cursor: string,
  node: Consumer,
};

export type CouponConnection = {
  __typename: "CouponConnection",
  total: number,
  edges:  Array<CouponEdge >,
  pageInfo: PageInfo,
};

export type CouponEdge = {
  __typename: "CouponEdge",
  cursor: string,
  node: Coupon,
};

export type LocationConnection = {
  __typename: "LocationConnection",
  total: number,
  edges:  Array<LocationEdge >,
  pageInfo: PageInfo,
};

export type LocationEdge = {
  __typename: "LocationEdge",
  cursor: string,
  node: Location,
};

export type PlanConnection = {
  __typename: "PlanConnection",
  total: number,
  edges:  Array<PlanEdge >,
  pageInfo: PageInfo,
};

export type PlanEdge = {
  __typename: "PlanEdge",
  cursor: string,
  node: Plan,
};

export type SmsPhoneNumberConnection = {
  __typename: "SmsPhoneNumberConnection",
  edges:  Array<SmsPhoneNumberEdge >,
  pageInfo: PageInfo,
};

export type SmsPhoneNumberEdge = {
  __typename: "SmsPhoneNumberEdge",
  cursor: string,
  node: SmsPhoneNumber,
};

export type SmsPhoneNumber = {
  __typename: "SmsPhoneNumber",
  phoneNumber: string,
  city?: string | null,
  stateOrProvince?: StateOrProvince | null,
  postalCode?: string | null,
  country: string,
  lat?: number | null,
  long?: number | null,
};

export type ConsumerGroupConnection = {
  __typename: "ConsumerGroupConnection",
  total: number,
  edges:  Array<ConsumerGroupEdge >,
  pageInfo: PageInfo,
};

export type ConsumerGroupEdge = {
  __typename: "ConsumerGroupEdge",
  cursor: string,
  node: ConsumerGroup,
};

export type ConsumerGroup = {
  __typename: "ConsumerGroup",
  id: string,
  name: string,
  selfManaged: boolean,
  members?: ConsumerConnection | null,
};

export type GetBusinessMetadataInput = {
  businessId: string,
};

export type FilterLocationsInput = {
  pathSegment?: string | null,
  statuses?: Array< LocationStatus > | null,
};

export type FilterSchedulesInput = {
  orderReadyStart?: DateRangeInput | null,
  orderCutoff?: DateRangeInput | null,
  statuses?: Array< ScheduleStatus > | null,
  type?: TransportationType | null,
  pickupAddressId?: string | null,
};

export type DateRangeInput = {
  start: string,
  end: string,
};

export type FilterPlansInput = {
  statuses?: Array< PlanStatus > | null,
};

export type PaginationInput = {
  first?: number | null,
  after?: string | null,
  last?: number | null,
  before?: string | null,
};

export type CreateConsumerCardInput = {
  cardId: string,
  consumerId: string,
  businessId: string,
};

export type CardDefaultPayload = {
  __typename: "CardDefaultPayload",
  card?: Card | null,
};

export type ArchiveConsumerCardInput = {
  cardId: string,
  consumerId: string,
  businessId: string,
};

export type GetCartInput = {
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type ClearCartInput = {
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type CartDefaultPayload = {
  __typename: "CartDefaultPayload",
  cart?: Cart | null,
};

export type CreateCartInput = {
  consumerId?: string | null,
  businessId: string,
  locationId: string,
  updateCartType?: UpdateCartTypeInput | null,
};

export type UpdateCartTypeInput = {
  type: TransportationType,
  addressDisplay?: string | null,
  addressId?: string | null,
  postalCode?: string | null,
};

export type UpdateCartInput = {
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
  activeScheduleId?: string | null,
  newConsumerId?: string | null,
  updateCartType?: UpdateCartTypeInput | null,
};

export type RefreshCartInput = {
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type DeleteCartInput = {
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type AddScheduleToCartInput = {
  scheduleId: string,
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
  dateRange: DateRangeInput,
  cutoff: string,
};

export type RemoveScheduleFromCartInput = {
  scheduleId: string,
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type AddOfferToCartInput = {
  offerId: string,
  scheduleId: string,
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
  quantity: number,
  title: string,
  override?: boolean | null,
  configurationSelections: Array< OfferConfigurationSelectionInput >,
  offerCartId?: string | null,
  note?: string | null,
};

export type OfferConfigurationSelectionInput = {
  title: string,
  titleId: string,
  optionIds: Array< string >,
};

export type RemoveOfferFromCartInput = {
  offerCartId: string,
  scheduleId: string,
  cartId: string,
  consumerId?: string | null,
  businessId: string,
  locationId: string,
};

export type TransferCartInput = {
  cartId: string,
  consumerId: string,
  businessId: string,
  locationId: string,
};

export type ExpireCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type CheckoutDefaultPayload = {
  __typename: "CheckoutDefaultPayload",
  checkout: Checkout,
};

export type Checkout = {
  __typename: "Checkout",
  id: string,
  type: TransportationType,
  status: OrderStatus,
  consumer: Consumer,
  cardId?: string | null,
  cardLastFour?: string | null,
  cardBrand?: string | null,
  consumerAddress?: Address | null,
  cost?: CheckoutCost | null,
  orders:  Array<Order >,
};

export type CheckoutCost = {
  __typename: "CheckoutCost",
  subtotal?: MonetaryValue | null,
  estimatedTax?: MonetaryValue | null,
  serviceFee?: MonetaryValue | null,
  deliveryFee?: MonetaryValue | null,
  couponAmount?: MonetaryValue | null,
  creditAmount?: MonetaryValue | null,
  netTotal?: MonetaryValue | null,
  total?: MonetaryValue | null,
  tip?: MonetaryValue | null,
};

export type CreateCheckoutInput = {
  consumerId: string,
  businessId: string,
  locationId: string,
  cartId?: string | null,
};

export type ApplyCardToCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  cardId: string,
};

export type ApplyCouponToCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  couponName: string,
};

export type ApplyCreditToCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type ApplyTipToCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  isAmount: boolean,
  percentage?: number | null,
  amount?: number | null,
};

export type ApplyDeliveryAddressToCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  addressId: string,
};

export type CompleteCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  clientMutationId: string,
};

export type GetCheckoutInput = {
  checkoutId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type CreateConsumerInput = {
  id: string,
  businessId: string,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  avatar?: string | null,
  smsEnabled: boolean,
  testClockId?: string | null,
};

export type ConsumerDefaultPayload = {
  __typename: "ConsumerDefaultPayload",
  consumer?: Consumer | null,
};

export type UpdateConsumerInput = {
  id: string,
  businessId: string,
  status?: UserStatus | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  avatar?: string | null,
  businessNote?: string | null,
  smsEnabled?: boolean | null,
  allergens?: Array< string > | null,
};

export type GetConsumerInput = {
  consumerId: string,
};

export type FilterTransactionInput = {
  statuses?: Array< AllOrderStatus > | null,
  types?: Array< TransactionType > | null,
  dateRange?: DateRangeInput | null,
  number?: string | null,
  consumerEmail?: string | null,
};

export enum AllOrderStatus {
  PURCHASED = "PURCHASED",
  LOCATION_CANCELED = "LOCATION_CANCELED",
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  BUSINESS_CANCELED = "BUSINESS_CANCELED",
  CONSUMER_CANCELED = "CONSUMER_CANCELED",
  COMPLETED = "COMPLETED",
}


export type FilterSubscriptionsInput = {
  status?: SubscriptionStatus | null,
};

export type GetGiftCardInput = {
  giftCardId: string,
  businessId: string,
  issuerId: string,
};

export type PurchaseGiftCardInput = {
  issuerId: string,
  businessId: string,
  cardId: string,
  recipientEmail: string,
  recipientName: string,
  price: number,
  clientMutationId: string,
  description?: string | null,
};

export type GiftCardDefaultPayload = {
  __typename: "GiftCardDefaultPayload",
  giftCard?: GiftCard | null,
};

export type RedeemGiftCardInput = {
  token: string,
  recipientId: string,
  businessId: string,
};

export type RedeemGiftCardPayload = {
  __typename: "RedeemGiftCardPayload",
  giftCard?: GiftCard | null,
  creditBalance?: MonetaryValue | null,
};

export type ByIdInput = {
  id: string,
};

export type GetOfferInput = {
  businessId: string,
  locationId: string,
  offerId: string,
};

export type GetOrderInput = {
  orderId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type TransitionOrderConsumerCanceledInput = {
  orderId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type OrderDefaultPayload = {
  __typename: "OrderDefaultPayload",
  order?: Order | null,
};

export type UpdateOrderInput = {
  orderId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
  note?: string | null,
};

export type ResendOrderReceiptInput = {
  businessId: string,
  locationId: string,
  consumerId: string,
  orderId: string,
};

export type GetPlanInput = {
  businessId: string,
  planId: string,
};

export type GetRecurringOrderInput = {
  recurringOrderId: string,
  businessId: string,
  locationId: string,
  consumerId: string,
};

export type GetScheduleInput = {
  scheduleId: string,
  businessId: string,
  locationId: string,
};

export type FilterOffersInput = {
  scheduleId?: string | null,
  dateRange?: DateRangeInput | null,
  statuses?: Array< OfferStatus > | null,
};

export type GetSubscriptionInput = {
  subscriptionId: string,
  businessId: string,
  consumerId: string,
};

export type CreateSubscriptionInput = {
  consumerId: string,
  planId: string,
  cardId: string,
  addressId: string,
  businessId: string,
  clientMutationId: string,
  fulfillment?: Array< PlanFulfillmentInput > | null,
  testClockId?: string | null,
};

export type PlanFulfillmentInput = {
  productId: string,
  day: DayOfWeek,
  quantity: number,
};

export type SubscriptionDefaultPayload = {
  __typename: "SubscriptionDefaultPayload",
  subscription?: Subscription | null,
};

export type CancelSubscriptionInput = {
  subscriptionId: string,
  businessId: string,
  consumerId: string,
  canceledBy: SubscriptionCanceler,
};

export enum SubscriptionCanceler {
  CONSUMER = "CONSUMER",
  BUSINESS = "BUSINESS",
}


export type UpdateSubscriptionInput = {
  subscriptionId: string,
  businessId: string,
  consumerId: string,
  cardId?: string | null,
  addressId?: string | null,
  collection?: SubscriptionCollectionStatus | null,
  resumesAt?: string | null,
  testClockId?: string | null,
};

export type UpdateSubscriptionFulfillmentInput = {
  subscriptionId: string,
  businessId: string,
  fulfillment: Array< PlanFulfillmentInput >,
};

export type CalculateTaxInput = {
  addressId: string,
  businessId: string,
  consumerId: string,
  amount: number,
};

export type GetConsumerAddressQueryVariables = {
  input: GetConsumerAddressInput,
};

export type GetConsumerAddressQuery = {
  consumerAddress?:  {
    __typename: "Address",
    id: string,
    createdAt: string,
    updatedAt: string,
    status: AddressStatus,
    street: string,
    street2?: string | null,
    city: string,
    postalCode: string,
    country: CountryCode,
    stateOrProvince?: StateOrProvince | null,
    lat: number,
    long: number,
    isDefault: boolean,
    instructions?: string | null,
  } | null,
};

export type CreateConsumerAddressMutationVariables = {
  input: CreateConsumerAddressInput,
};

export type CreateConsumerAddressMutation = {
  createConsumerAddress?:  {
    __typename: "AddressDefaultPayload",
    address?:  {
      __typename: "Address",
      id: string,
      street: string,
      street2?: string | null,
      city: string,
      stateOrProvince?: StateOrProvince | null,
      postalCode: string,
      country: CountryCode,
    } | null,
  } | null,
};

export type UpdateConsumerAddressMutationVariables = {
  input: UpdateConsumerAddressInput,
};

export type UpdateConsumerAddressMutation = {
  updateConsumerAddress?:  {
    __typename: "AddressDefaultPayload",
    address?:  {
      __typename: "Address",
      id: string,
      street: string,
      street2?: string | null,
      city: string,
      stateOrProvince?: StateOrProvince | null,
      postalCode: string,
      country: CountryCode,
    } | null,
  } | null,
};

export type ArchiveConsumerAddressMutationVariables = {
  input: ArchiveConsumerAddressInput,
};

export type ArchiveConsumerAddressMutation = {
  archiveConsumerAddress?:  {
    __typename: "AddressDefaultPayload",
    address?:  {
      __typename: "Address",
      id: string,
      street: string,
      street2?: string | null,
      city: string,
      stateOrProvince?: StateOrProvince | null,
      postalCode: string,
      country: CountryCode,
    } | null,
  } | null,
};

export type GetBusinessAuthQueryVariables = {
  input: GetBusinessInput,
};

export type GetBusinessAuthQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    cognito?:  {
      __typename: "CognitoIdentity",
      identityPoolId: string,
      userPoolId: string,
      userPoolClientId: string,
    } | null,
  } | null,
};

export type GetBusinessSettingsQueryVariables = {
  input: GetBusinessInput,
};

export type GetBusinessSettingsQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    settings:  {
      __typename: "BusinessSettings",
      subscriptionAllowPause: boolean,
      subscriptionAllowCancel: boolean,
      orderAllowCancel: boolean,
    },
  } | null,
};

export type GetBusinessMetadataQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessMetadataQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    id: string,
    createdAt: string,
    updatedAt: string,
    title: string,
    subdomain: string,
    customDomain?: string | null,
    pathSegments: Array< string >,
    avatarImage?: string | null,
    coverImage?: string | null,
    toolbarImage?: string | null,
    supportPhoneNumber: string,
    supportEmail: string,
    showPromotePlans: boolean,
    showGiftCard: boolean,
    promotePlansText: string,
    supportWidget?: string | null,
    banner?: string | null,
    colorTheme: ColorTheme,
    metaPixelId?: string | null,
    headline:  {
      __typename: "Headline",
      primary: string,
      secondary: string,
    },
    howItWorks:  Array< {
      __typename: "HowItWorksItem",
      title: string,
      subtitle: string,
      icon?: string | null,
    } >,
    social:  {
      __typename: "Social",
      facebook?: string | null,
      instagram?: string | null,
      linkedin?: string | null,
      twitter?: string | null,
    },
    faqs:  Array< {
      __typename: "Faq",
      id: string,
      createdAt: string,
      updatedAt: string,
      question: string,
      answer: string,
    } >,
    testimonials:  Array< {
      __typename: "Testimonial",
      id: string,
      createdAt: string,
      updatedAt: string,
      name: string,
      city: string,
      message: string,
    } >,
    featuredProducts:  Array< {
      __typename: "Product",
      id: string,
      title: string,
      description?: string | null,
      images: Array< string >,
      nutrition?:  {
        __typename: "Nutrition",
        calorie?: number | null,
        protein?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
      } | null,
    } >,
    giftCard:  {
      __typename: "GiftCardSetting",
      minPrice:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      },
      maxPrice:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      },
    },
    marketing?:  {
      __typename: "BusinessMarketing",
      provider: MarketingProvider,
      enabled: boolean,
      integrationScript?: string | null,
      hostedSignUpUrl?: string | null,
    } | null,
  } | null,
};

export type GetBusinessAboutUsTeamMembersQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessAboutUsTeamMembersQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    id: string,
    aboutUs:  {
      __typename: "AboutUs",
      mission: string,
      origin: string,
    },
    teamMemberProfiles:  Array< {
      __typename: "TeamMemberProfile",
      id: string,
      name: string,
      title: string,
      bio: string,
      picture?: string | null,
      position: number,
    } >,
  } | null,
};

export type GetBusinessImagesQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessImagesQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    toolbarImage?: string | null,
    avatarImage?: string | null,
  } | null,
};

export type GetBusinessFaqsQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessFaqsQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    faqs:  Array< {
      __typename: "Faq",
      id: string,
      createdAt: string,
      updatedAt: string,
      question: string,
      answer: string,
    } >,
  } | null,
};

export type GetBusinessLocationsQueryVariables = {
  input: GetBusinessInput,
  filterInput?: FilterLocationsInput | null,
};

export type GetBusinessLocationsQuery = {
  getBusiness?:  {
    __typename: "Business",
    locations?:  {
      __typename: "LocationConnection",
      edges:  Array< {
        __typename: "LocationEdge",
        node:  {
          __typename: "Location",
          id: string,
          title: string,
          pathSegment: string,
          status: LocationStatus,
          description?: string | null,
          awayMessage?: string | null,
          website?: string | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetBusinessLocationsPickerQueryVariables = {
  input: GetBusinessInput,
  filterLocationsInput?: FilterLocationsInput | null,
  filterSchedulesInput?: FilterSchedulesInput | null,
};

export type GetBusinessLocationsPickerQuery = {
  getBusiness?:  {
    __typename: "Business",
    locations?:  {
      __typename: "LocationConnection",
      edges:  Array< {
        __typename: "LocationEdge",
        node:  {
          __typename: "Location",
          id: string,
          status: LocationStatus,
          title: string,
          pathSegment: string,
          deliveries?:  {
            __typename: "DeliveryConnection",
            edges:  Array< {
              __typename: "DeliveryEdge",
              node:  {
                __typename: "Delivery",
                id: string,
                postalCodes: Array< string >,
              },
            } >,
          } | null,
          schedules?:  {
            __typename: "ScheduleConnection",
            edges:  Array< {
              __typename: "ScheduleEdge",
              node:  {
                __typename: "Schedule",
                id: string,
                status: ScheduleStatus,
                type: TransportationType,
                orderReadyStart: string,
                orderReadyEnd: string,
                orderCutoff: string,
                pickupAddress?:  {
                  __typename: "Address",
                  id: string,
                  createdAt: string,
                  updatedAt: string,
                  isDefault: boolean,
                  status: AddressStatus,
                  street: string,
                  street2?: string | null,
                  city: string,
                  stateOrProvince?: StateOrProvince | null,
                  country: CountryCode,
                  postalCode: string,
                  title?: string | null,
                  lat: number,
                  long: number,
                } | null,
              },
            } >,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetBusinessProductAllergensQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessProductAllergensQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    productAllergens: Array< string >,
  } | null,
};

export type GetBusinessProductCategoriesQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessProductCategoriesQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    id: string,
    productCategories: Array< string >,
  } | null,
};

export type GetBusinessPlansQueryVariables = {
  input: GetBusinessInput,
  filterInput?: FilterPlansInput | null,
  pagination?: PaginationInput | null,
};

export type GetBusinessPlansQuery = {
  getBusiness?:  {
    __typename: "Business",
    plans?:  {
      __typename: "PlanConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "PlanEdge",
        node:  {
          __typename: "Plan",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: PlanStatus,
          title: string,
          interval: PlanInterval,
          images: Array< string >,
          description?: string | null,
          transportationType: TransportationType,
          chargeDay: DayOfWeek,
          allowSelection: boolean,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          pickupAddress?:  {
            __typename: "Address",
            id: string,
            isDefault: boolean,
            createdAt: string,
            updatedAt: string,
            status: AddressStatus,
            lat: number,
            long: number,
            street: string,
            street2?: string | null,
            city: string,
            stateOrProvince?: StateOrProvince | null,
            postalCode: string,
            country: CountryCode,
          } | null,
          fulfillment:  Array< {
            __typename: "PlanFulfillment",
            quantity: number,
            day: DayOfWeek,
            product:  {
              __typename: "Product",
              id: string,
              title: string,
              images: Array< string >,
              description?: string | null,
            },
          } >,
        },
      } >,
    } | null,
  } | null,
};

export type GetBusinessLegalQueryVariables = {
  input: GetBusinessInput,
};

export type GetBusinessLegalQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    metadata:  {
      __typename: "BusinessMetadata",
      subdomain: string,
      customDomain?: string | null,
      title: string,
      supportPhoneNumber: string,
      supportEmail: string,
    },
    address:  {
      __typename: "Address",
      id: string,
      createdAt: string,
      updatedAt: string,
      lat: number,
      long: number,
      isDefault: boolean,
      status: AddressStatus,
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
    },
  } | null,
};

export type GetBusinessPromotedScheduleQueryVariables = {
  input: GetBusinessMetadataInput,
};

export type GetBusinessPromotedScheduleQuery = {
  getBusinessMetadata?:  {
    __typename: "BusinessMetadata",
    promotedSchedule?:  {
      __typename: "Schedule",
      id: string,
      type: TransportationType,
      title?: string | null,
      orderReadyStart: string,
      orderReadyEnd: string,
      orderCutoff: string,
      location:  {
        __typename: "Location",
        pathSegment: string,
      },
      pickupAddress?:  {
        __typename: "Address",
        id: string,
        createdAt: string,
        updatedAt: string,
        title?: string | null,
        lat: number,
        long: number,
        isDefault: boolean,
        status: AddressStatus,
        street: string,
        street2?: string | null,
        city: string,
        postalCode: string,
        country: CountryCode,
        stateOrProvince?: StateOrProvince | null,
      } | null,
    } | null,
  } | null,
};

export type CreateConsumerCardMutationVariables = {
  input: CreateConsumerCardInput,
};

export type CreateConsumerCardMutation = {
  createConsumerCard?:  {
    __typename: "CardDefaultPayload",
    card?:  {
      __typename: "Card",
      id: string,
      lastFour: string,
      brand: string,
      expMonth: number,
      expYear: number,
    } | null,
  } | null,
};

export type ArchiveConsumerCardMutationVariables = {
  input: ArchiveConsumerCardInput,
};

export type ArchiveConsumerCardMutation = {
  archiveConsumerCard?:  {
    __typename: "CardDefaultPayload",
    card?:  {
      __typename: "Card",
      id: string,
      lastFour: string,
      brand: string,
      expMonth: number,
      expYear: number,
    } | null,
  } | null,
};

export type GetCartQueryVariables = {
  input: GetCartInput,
};

export type GetCartQuery = {
  cart?:  {
    __typename: "Cart",
    id: string,
    createdAt: string,
    updatedAt: string,
    consumerId?: string | null,
    businessId: string,
    locationId: string,
    addressId?: string | null,
    activeScheduleId?: string | null,
    addressDisplay?: string | null,
    postalCode?: string | null,
    type?: TransportationType | null,
    subtotal:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        abbreviation: CurrencyAbbreviation,
        symbol: string,
      },
    },
    cartSchedules:  Array< {
      __typename: "CartSchedule",
      scheduleId: string,
      title?: string | null,
      cutoff: string,
      dateRange:  {
        __typename: "DateRange",
        start: string,
        end: string,
      },
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartOffers:  Array< {
        __typename: "CartOffer",
        id: string,
        offerId: string,
        title: string,
        quantity: number,
        note?: string | null,
        unitPrice:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        configurationSelections:  Array< {
          __typename: "OfferConfigurationSelection",
          titleId: string,
          title: string,
          selections:  Array< {
            __typename: "OfferConfigurationOptionSelection",
            optionId: string,
            option: string,
            price:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                abbreviation: CurrencyAbbreviation,
                symbol: string,
              },
            },
          } >,
        } >,
      } >,
    } >,
  } | null,
};

export type ClearCartMutationVariables = {
  input: ClearCartInput,
};

export type ClearCartMutation = {
  clearCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type CreateCartMutationVariables = {
  input: CreateCartInput,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type UpdateCartMutationVariables = {
  input: UpdateCartInput,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type RefreshCartMutationVariables = {
  input: RefreshCartInput,
};

export type RefreshCartMutation = {
  refreshCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type DeleteCartMutationVariables = {
  input: DeleteCartInput,
};

export type DeleteCartMutation = {
  deleteCart?: boolean | null,
};

export type AddScheduleToCartMutationVariables = {
  input: AddScheduleToCartInput,
};

export type AddScheduleToCartMutation = {
  addScheduleToCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type RemoveScheduleFromCartMutationVariables = {
  input: RemoveScheduleFromCartInput,
};

export type RemoveScheduleFromCartMutation = {
  removeScheduleFromCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type AddOfferToCartMutationVariables = {
  input: AddOfferToCartInput,
};

export type AddOfferToCartMutation = {
  addOfferToCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type RemoveOfferFromCartMutationVariables = {
  input: RemoveOfferFromCartInput,
};

export type RemoveOfferFromCartMutation = {
  removeOfferFromCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
      createdAt: string,
      updatedAt: string,
      consumerId?: string | null,
      businessId: string,
      locationId: string,
      addressId?: string | null,
      activeScheduleId?: string | null,
      addressDisplay?: string | null,
      postalCode?: string | null,
      type?: TransportationType | null,
      subtotal:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      cartSchedules:  Array< {
        __typename: "CartSchedule",
        scheduleId: string,
        cutoff: string,
        dateRange:  {
          __typename: "DateRange",
          start: string,
          end: string,
        },
        subtotal:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        cartOffers:  Array< {
          __typename: "CartOffer",
          id: string,
          offerId: string,
          title: string,
          quantity: number,
          note?: string | null,
          unitPrice:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          configurationSelections:  Array< {
            __typename: "OfferConfigurationSelection",
            titleId: string,
            title: string,
            selections:  Array< {
              __typename: "OfferConfigurationOptionSelection",
              optionId: string,
              option: string,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        } >,
      } >,
    } | null,
  } | null,
};

export type TransferCartMutationVariables = {
  input: TransferCartInput,
};

export type TransferCartMutation = {
  transferCart?:  {
    __typename: "CartDefaultPayload",
    cart?:  {
      __typename: "Cart",
      id: string,
    } | null,
  } | null,
};

export type ExpireCheckoutMutationVariables = {
  input: ExpireCheckoutInput,
};

export type ExpireCheckoutMutation = {
  expireCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      orders:  Array< {
        __typename: "Order",
        id: string,
      } >,
    },
  } | null,
};

export type CreateCheckoutMutationVariables = {
  input: CreateCheckoutInput,
};

export type CreateCheckoutMutation = {
  createCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      orders:  Array< {
        __typename: "Order",
        id: string,
      } >,
      cost?:  {
        __typename: "CheckoutCost",
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
    },
  } | null,
};

export type ApplyCardToCheckoutMutationVariables = {
  input: ApplyCardToCheckoutInput,
};

export type ApplyCardToCheckoutMutation = {
  applyCardToCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      orders:  Array< {
        __typename: "Order",
        id: string,
        updatedAt: string,
        cardLastFour?: string | null,
        cardBrand?: string | null,
      } >,
    },
  } | null,
};

export type ApplyCouponToCheckoutMutationVariables = {
  input: ApplyCouponToCheckoutInput,
};

export type ApplyCouponToCheckoutMutation = {
  applyCouponToCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      cost?:  {
        __typename: "CheckoutCost",
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        deliveryFee?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        couponAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
      orders:  Array< {
        __typename: "Order",
        id: string,
        createdAt: string,
        updatedAt: string,
        orderType: TransportationType,
        note?: string | null,
        orderItems:  Array< {
          __typename: "OrderItem",
          title: string,
          quantity: number,
          note?: string | null,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        } >,
        cost?:  {
          __typename: "OrderCost",
          subtotal?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          estimatedTax?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          deliveryFee?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          couponAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          creditAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          total?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
        } | null,
      } >,
    },
  } | null,
};

export type ApplyCreditToCheckoutMutationVariables = {
  input: ApplyCreditToCheckoutInput,
};

export type ApplyCreditToCheckoutMutation = {
  applyCreditToCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      cost?:  {
        __typename: "CheckoutCost",
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        deliveryFee?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        couponAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        tip?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
      orders:  Array< {
        __typename: "Order",
        id: string,
        createdAt: string,
        updatedAt: string,
        orderType: TransportationType,
        note?: string | null,
        orderItems:  Array< {
          __typename: "OrderItem",
          title: string,
          quantity: number,
          note?: string | null,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        } >,
        cost?:  {
          __typename: "OrderCost",
          subtotal?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          estimatedTax?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          deliveryFee?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          couponAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          creditAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          total?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
        } | null,
      } >,
    },
  } | null,
};

export type ApplyTipToCheckoutMutationVariables = {
  input: ApplyTipToCheckoutInput,
};

export type ApplyTipToCheckoutMutation = {
  applyTipToCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      cost?:  {
        __typename: "CheckoutCost",
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        deliveryFee?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        couponAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        tip?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
      orders:  Array< {
        __typename: "Order",
        id: string,
        createdAt: string,
        updatedAt: string,
        orderType: TransportationType,
        note?: string | null,
        orderItems:  Array< {
          __typename: "OrderItem",
          title: string,
          quantity: number,
          note?: string | null,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        } >,
        cost?:  {
          __typename: "OrderCost",
          subtotal?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          estimatedTax?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          deliveryFee?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          couponAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          creditAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          total?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
        } | null,
      } >,
    },
  } | null,
};

export type ApplyDeliveryAddressToCheckoutMutationVariables = {
  input: ApplyDeliveryAddressToCheckoutInput,
};

export type ApplyDeliveryAddressToCheckoutMutation = {
  applyDeliveryAddressToCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      cost?:  {
        __typename: "CheckoutCost",
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        deliveryFee?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        couponAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
      orders:  Array< {
        __typename: "Order",
        id: string,
        createdAt: string,
        updatedAt: string,
        orderType: TransportationType,
        note?: string | null,
        orderItems:  Array< {
          __typename: "OrderItem",
          title: string,
          quantity: number,
          note?: string | null,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        } >,
        consumerAddress?:  {
          __typename: "Address",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: AddressStatus,
          street: string,
          street2?: string | null,
          city: string,
          postalCode: string,
          country: CountryCode,
          stateOrProvince?: StateOrProvince | null,
          lat: number,
          long: number,
          isDefault: boolean,
          instructions?: string | null,
        } | null,
        cost?:  {
          __typename: "OrderCost",
          subtotal?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          estimatedTax?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          deliveryFee?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          couponAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          creditAmount?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
          total?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          } | null,
        } | null,
      } >,
    },
  } | null,
};

export type CompleteCheckoutMutationVariables = {
  input: CompleteCheckoutInput,
};

export type CompleteCheckoutMutation = {
  completeCheckout?:  {
    __typename: "CheckoutDefaultPayload",
    checkout:  {
      __typename: "Checkout",
      id: string,
      orders:  Array< {
        __typename: "Order",
        id: string,
      } >,
    },
  } | null,
};

export type GetCheckoutQueryVariables = {
  input: GetCheckoutInput,
};

export type GetCheckoutQuery = {
  checkout?:  {
    __typename: "Checkout",
    id: string,
    type: TransportationType,
    cardId?: string | null,
    cardBrand?: string | null,
    cardLastFour?: string | null,
    consumer:  {
      __typename: "Consumer",
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber?: string | null,
    },
    consumerAddress?:  {
      __typename: "Address",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: AddressStatus,
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
      lat: number,
      long: number,
      isDefault: boolean,
      instructions?: string | null,
    } | null,
    cost?:  {
      __typename: "CheckoutCost",
      subtotal?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      estimatedTax?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      deliveryFee?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      couponAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      creditAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      tip?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      total?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
    } | null,
    orders:  Array< {
      __typename: "Order",
      id: string,
      createdAt: string,
      updatedAt: string,
      orderType: TransportationType,
      cardLastFour?: string | null,
      cardBrand?: string | null,
      note?: string | null,
      number?: string | null,
      orderItems:  Array< {
        __typename: "OrderItem",
        title: string,
        quantity: number,
        note?: string | null,
        price:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
        configurationSelections:  Array< {
          __typename: "OfferConfigurationSelection",
          titleId: string,
          title: string,
          selections:  Array< {
            __typename: "OfferConfigurationOptionSelection",
            optionId: string,
            option: string,
            price:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                abbreviation: CurrencyAbbreviation,
                symbol: string,
              },
            },
          } >,
        } >,
      } >,
      consumer:  {
        __typename: "Consumer",
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber?: string | null,
      },
      schedule:  {
        __typename: "Schedule",
        id: string,
        orderReadyStart: string,
        orderReadyEnd: string,
        orderCutoff: string,
      },
      consumerAddress?:  {
        __typename: "Address",
        id: string,
        createdAt: string,
        updatedAt: string,
        status: AddressStatus,
        street: string,
        street2?: string | null,
        city: string,
        postalCode: string,
        country: CountryCode,
        stateOrProvince?: StateOrProvince | null,
        lat: number,
        long: number,
        isDefault: boolean,
        instructions?: string | null,
      } | null,
      pickupAddress?:  {
        __typename: "Address",
        id: string,
        createdAt: string,
        updatedAt: string,
        status: AddressStatus,
        street: string,
        street2?: string | null,
        city: string,
        postalCode: string,
        country: CountryCode,
        stateOrProvince?: StateOrProvince | null,
        lat: number,
        long: number,
        title?: string | null,
        isDefault: boolean,
        instructions?: string | null,
      } | null,
      cost?:  {
        __typename: "OrderCost",
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        deliveryFee?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        couponAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
    } >,
  } | null,
};

export type GetCheckoutCompleteQueryVariables = {
  input: GetCheckoutInput,
};

export type GetCheckoutCompleteQuery = {
  checkout?:  {
    __typename: "Checkout",
    id: string,
    orders:  Array< {
      __typename: "Order",
      id: string,
      createdAt: string,
      orderType: TransportationType,
      orderStatus: OrderStatus,
      number?: string | null,
      orderItems:  Array< {
        __typename: "OrderItem",
        title: string,
        quantity: number,
      } >,
      schedule:  {
        __typename: "Schedule",
        id: string,
        orderReadyStart: string,
        orderReadyEnd: string,
        orderCutoff: string,
      },
      cost?:  {
        __typename: "OrderCost",
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        } | null,
      } | null,
    } >,
  } | null,
};

export type CreateConsumerMutationVariables = {
  input: CreateConsumerInput,
};

export type CreateConsumerMutation = {
  createConsumer?:  {
    __typename: "ConsumerDefaultPayload",
    consumer?:  {
      __typename: "Consumer",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber?: string | null,
    } | null,
  } | null,
};

export type UpdateConsumerMutationVariables = {
  input: UpdateConsumerInput,
};

export type UpdateConsumerMutation = {
  updateConsumer?:  {
    __typename: "ConsumerDefaultPayload",
    consumer?:  {
      __typename: "Consumer",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber?: string | null,
    } | null,
  } | null,
};

export type GetConsumerQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string | null,
    creditBalance:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        symbol: string,
        abbreviation: CurrencyAbbreviation,
      },
    },
  } | null,
};

export type GetConsumerProfileQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerProfileQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string | null,
    smsEnabled: boolean,
  } | null,
};

export type GetConsumerDefaultAddressQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerDefaultAddressQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    defaultAddress?:  {
      __typename: "Address",
      id: string,
      createdAt: string,
      updatedAt: string,
      isDefault: boolean,
      status: AddressStatus,
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
      lat: number,
      long: number,
    } | null,
  } | null,
};

export type GetConsumerAddressesQueryVariables = {
  input: GetConsumerInput,
  pagination?: PaginationInput | null,
};

export type GetConsumerAddressesQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    addresses?:  {
      __typename: "AddressConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        startCursor?: string | null,
        endCursor?: string | null,
        hasPreviousPage?: boolean | null,
        hasNextPage?: boolean | null,
      },
      edges:  Array< {
        __typename: "AddressEdge",
        cursor: string,
        node:  {
          __typename: "Address",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: AddressStatus,
          street: string,
          street2?: string | null,
          city: string,
          postalCode: string,
          country: CountryCode,
          stateOrProvince?: StateOrProvince | null,
          lat: number,
          long: number,
          isDefault: boolean,
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerAllergensQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerAllergensQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    allergens: Array< string >,
  } | null,
};

export type GetConsumerCardsQueryVariables = {
  input: GetConsumerInput,
  pagination?: PaginationInput | null,
};

export type GetConsumerCardsQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    cards?:  {
      __typename: "CardConnection",
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "CardEdge",
        node:  {
          __typename: "Card",
          id: string,
          lastFour: string,
          brand: string,
          expMonth: number,
          expYear: number,
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerOrdersQueryVariables = {
  input: GetConsumerInput,
  pagination?: PaginationInput | null,
};

export type GetConsumerOrdersQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    orders?:  {
      __typename: "OrderConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "OrderEdge",
        node:  {
          __typename: "Order",
          id: string,
          locationId: string,
          number?: string | null,
          createdAt: string,
          orderType: TransportationType,
          orderStatus: OrderStatus,
          planTitle?: string | null,
          orderItems:  Array< {
            __typename: "OrderItem",
            title: string,
            quantity: number,
            note?: string | null,
            price:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                abbreviation: CurrencyAbbreviation,
                symbol: string,
              },
            },
            configurationSelections:  Array< {
              __typename: "OfferConfigurationSelection",
              titleId: string,
              title: string,
              selections:  Array< {
                __typename: "OfferConfigurationOptionSelection",
                optionId: string,
                option: string,
                price:  {
                  __typename: "MonetaryValue",
                  amount: number,
                  currency:  {
                    __typename: "Currency",
                    abbreviation: CurrencyAbbreviation,
                    symbol: string,
                  },
                },
              } >,
            } >,
          } >,
          schedule:  {
            __typename: "Schedule",
            id: string,
            orderReadyStart: string,
            orderReadyEnd: string,
            orderCutoff: string,
          },
          cost?:  {
            __typename: "OrderCost",
            total?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                abbreviation: CurrencyAbbreviation,
                symbol: string,
              },
            } | null,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerAllOrdersQueryVariables = {
  input: GetConsumerInput,
  filters?: FilterTransactionInput | null,
  pagination?: PaginationInput | null,
};

export type GetConsumerAllOrdersQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    transactions?:  {
      __typename: "TransactionConnection",
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "TransactionEdge",
        node: ( {
            __typename: "Order",
            id: string,
            number?: string | null,
            checkoutId: string,
            locationId: string,
            createdAt: string,
            updatedAt: string,
            transactionType: TransactionType,
            orderStatus: OrderStatus,
            orderType: TransportationType,
            note?: string | null,
            schedule:  {
              __typename: string,
              orderReadyStart: string,
              orderReadyEnd: string,
            },
            orderItems:  Array< {
              __typename: string,
              title: string,
              quantity: number,
              note?: string | null,
              price:  {
                __typename: string,
                amount: number,
                currency:  {
                  __typename: string,
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
              configurationSelections:  Array< {
                __typename: string,
                titleId: string,
                title: string,
                selections:  Array< {
                  __typename: string,
                  optionId: string,
                  option: string,
                  price:  {
                    __typename: string,
                    amount: number,
                    currency:  {
                      __typename: string,
                      abbreviation: CurrencyAbbreviation,
                      symbol: string,
                    },
                  },
                } >,
              } >,
            } >,
            creditBalanceSnapshot:  {
              __typename: string,
              amount: number,
              currency:  {
                __typename: string,
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            },
            cost?:  {
              __typename: string,
              total?:  {
                __typename: string,
                amount: number,
                currency:  {
                  __typename: string,
                  symbol: string,
                  abbreviation: CurrencyAbbreviation,
                },
              } | null,
            } | null,
          } | {
            __typename: "RecurringOrder",
            id: string,
            number?: string | null,
            locationId: string,
            createdAt: string,
            updatedAt: string,
            fulfillOn: string,
            transactionType: TransactionType,
            recurringOrderStatus: RecurringOrderStatus,
            orderType: TransportationType,
            orderItems:  Array< {
              __typename: string,
              title: string,
              quantity: number,
              price:  {
                __typename: string,
                amount: number,
                currency:  {
                  __typename: string,
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
            cost?:  {
              __typename: string,
              total?:  {
                __typename: string,
                amount: number,
                currency:  {
                  __typename: string,
                  symbol: string,
                  abbreviation: CurrencyAbbreviation,
                },
              } | null,
            } | null,
          } | {
            __typename: "SubscriptionInvoice",
          } | {
            __typename: "Credit",
          } | {
            __typename: "Refund",
          } | {
            __typename: "GiftCard",
          }
        ),
      } >,
    } | null,
  } | null,
};

export type GetConsumerGiftCardsQueryVariables = {
  input: GetConsumerInput,
  pagination?: PaginationInput | null,
};

export type GetConsumerGiftCardsQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    giftCards?:  {
      __typename: "GiftCardConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "GiftCardEdge",
        node:  {
          __typename: "GiftCard",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: GiftCardStatus,
          redemptionType: GiftCardRedemptionType,
          issuerId: string,
          recipientEmail: string,
          recipientName: string,
          recipientId?: string | null,
          description?: string | null,
          card:  {
            __typename: "Card",
            lastFour: string,
            brand: string,
            expMonth: number,
            expYear: number,
          },
          cost?:  {
            __typename: "GiftCardCost",
            price?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
            subtotal?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
            estimatedTax?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
            creditAmount?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
            netTotal?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
            total?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerSubscriptionsQueryVariables = {
  input: GetConsumerInput,
  filter?: FilterSubscriptionsInput | null,
  pagination?: PaginationInput | null,
};

export type GetConsumerSubscriptionsQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    subscriptions?:  {
      __typename: "SubscriptionConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "SubscriptionEdge",
        node:  {
          __typename: "Subscription",
          id: string,
          status: SubscriptionStatus,
          collection: SubscriptionCollectionStatus,
          createdAt: string,
          card?:  {
            __typename: "Card",
            id: string,
            lastFour: string,
            brand: string,
            expMonth: number,
            expYear: number,
          } | null,
          fulfillment:  Array< {
            __typename: "PlanFulfillment",
            quantity: number,
            day: DayOfWeek,
            product:  {
              __typename: "Product",
              id: string,
              title: string,
              images: Array< string >,
              description?: string | null,
            },
          } >,
          plan:  {
            __typename: "Plan",
            id: string,
            title: string,
            interval: PlanInterval,
            allowSelection: boolean,
            price:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                abbreviation: CurrencyAbbreviation,
                symbol: string,
              },
            },
          },
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerMenuInfoQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerMenuInfoQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    allergens: Array< string >,
    creditBalance:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        symbol: string,
        abbreviation: CurrencyAbbreviation,
      },
    },
  } | null,
};

export type GetConsumerPlanCheckoutQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerPlanCheckoutQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string | null,
    creditBalance:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        symbol: string,
        abbreviation: CurrencyAbbreviation,
      },
    },
    addresses?:  {
      __typename: "AddressConnection",
      edges:  Array< {
        __typename: "AddressEdge",
        node:  {
          __typename: "Address",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: AddressStatus,
          street: string,
          street2?: string | null,
          city: string,
          postalCode: string,
          country: CountryCode,
          stateOrProvince?: StateOrProvince | null,
          lat: number,
          long: number,
          isDefault: boolean,
        },
      } >,
    } | null,
    cards?:  {
      __typename: "CardConnection",
      edges:  Array< {
        __typename: "CardEdge",
        node:  {
          __typename: "Card",
          id: string,
          lastFour: string,
          brand: string,
          expMonth: number,
          expYear: number,
        },
      } >,
    } | null,
  } | null,
};

export type GetConsumerGiftCardCheckoutQueryVariables = {
  input: GetConsumerInput,
};

export type GetConsumerGiftCardCheckoutQuery = {
  getConsumer?:  {
    __typename: "Consumer",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string | null,
    cards?:  {
      __typename: "CardConnection",
      edges:  Array< {
        __typename: "CardEdge",
        node:  {
          __typename: "Card",
          id: string,
          lastFour: string,
          brand: string,
          expMonth: number,
          expYear: number,
        },
      } >,
    } | null,
  } | null,
};

export type GetGiftCardQueryVariables = {
  input: GetGiftCardInput,
};

export type GetGiftCardQuery = {
  giftCard?:  {
    __typename: "GiftCard",
    id: string,
    createdAt: string,
    updatedAt: string,
    redeemedAt?: string | null,
    status: GiftCardStatus,
    redemptionType: GiftCardRedemptionType,
    issuerId: string,
    recipientEmail: string,
    recipientName: string,
    recipientId?: string | null,
    description?: string | null,
    card:  {
      __typename: "Card",
      lastFour: string,
      brand: string,
      expMonth: number,
      expYear: number,
    },
    cost?:  {
      __typename: "GiftCardCost",
      price?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      subtotal?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      estimatedTax?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      creditAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      netTotal?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      total?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
    } | null,
  } | null,
};

export type PurchaseGiftCardMutationVariables = {
  input: PurchaseGiftCardInput,
};

export type PurchaseGiftCardMutation = {
  purchaseGiftCard?:  {
    __typename: "GiftCardDefaultPayload",
    giftCard?:  {
      __typename: "GiftCard",
      id: string,
      createdAt: string,
      updatedAt: string,
      redeemedAt?: string | null,
      status: GiftCardStatus,
      redemptionType: GiftCardRedemptionType,
      issuerId: string,
      recipientEmail: string,
      recipientName: string,
      recipientId?: string | null,
      description?: string | null,
      card:  {
        __typename: "Card",
        lastFour: string,
        brand: string,
        expMonth: number,
        expYear: number,
      },
      cost?:  {
        __typename: "GiftCardCost",
        price?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        netTotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
      } | null,
    } | null,
  } | null,
};

export type RedeemGiftCardMutationVariables = {
  input: RedeemGiftCardInput,
};

export type RedeemGiftCardMutation = {
  redeemGiftCard?:  {
    __typename: "RedeemGiftCardPayload",
    giftCard?:  {
      __typename: "GiftCard",
      id: string,
      createdAt: string,
      updatedAt: string,
      redeemedAt?: string | null,
      status: GiftCardStatus,
      redemptionType: GiftCardRedemptionType,
      issuerId: string,
      recipientEmail: string,
      recipientName: string,
      recipientId?: string | null,
      description?: string | null,
      card:  {
        __typename: "Card",
        lastFour: string,
        brand: string,
        expMonth: number,
        expYear: number,
      },
      cost?:  {
        __typename: "GiftCardCost",
        price?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        subtotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        estimatedTax?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        creditAmount?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        netTotal?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
        total?:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            symbol: string,
            abbreviation: CurrencyAbbreviation,
          },
        } | null,
      } | null,
    } | null,
  } | null,
};

export type GetBusinessLocationSchedulePickerQueryVariables = {
  input: GetBusinessInput,
  filterLocationsInput: FilterLocationsInput,
  filterSchedulesInput: FilterSchedulesInput,
};

export type GetBusinessLocationSchedulePickerQuery = {
  getBusiness?:  {
    __typename: "Business",
    locations?:  {
      __typename: "LocationConnection",
      edges:  Array< {
        __typename: "LocationEdge",
        node:  {
          __typename: "Location",
          id: string,
          pathSegment: string,
          deliveries?:  {
            __typename: "DeliveryConnection",
            edges:  Array< {
              __typename: "DeliveryEdge",
              node:  {
                __typename: "Delivery",
                id: string,
                postalCodes: Array< string >,
              },
            } >,
          } | null,
          schedules?:  {
            __typename: "ScheduleConnection",
            edges:  Array< {
              __typename: "ScheduleEdge",
              node:  {
                __typename: "Schedule",
                id: string,
                title?: string | null,
                status: ScheduleStatus,
                type: TransportationType,
                orderReadyStart: string,
                orderReadyEnd: string,
                orderCutoff: string,
                pickupAddress?:  {
                  __typename: "Address",
                  id: string,
                  street: string,
                  street2?: string | null,
                  city: string,
                  stateOrProvince?: StateOrProvince | null,
                  postalCode: string,
                  title?: string | null,
                } | null,
              },
            } >,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetLocationSchedulesQueryVariables = {
  input: ByIdInput,
  filterSchedulesInput: FilterSchedulesInput,
};

export type GetLocationSchedulesQuery = {
  getLocation?:  {
    __typename: "Location",
    schedules?:  {
      __typename: "ScheduleConnection",
      edges:  Array< {
        __typename: "ScheduleEdge",
        node:  {
          __typename: "Schedule",
          id: string,
          createdAt: string,
          updatedAt: string,
          title?: string | null,
          status: ScheduleStatus,
          type: TransportationType,
          orderReadyStart: string,
          orderReadyEnd: string,
          orderCutoff: string,
          pickupAddress?:  {
            __typename: "Address",
            id: string,
            createdAt: string,
            updatedAt: string,
            status: AddressStatus,
            street: string,
            street2?: string | null,
            city: string,
            stateOrProvince?: StateOrProvince | null,
            postalCode: string,
            country: CountryCode,
            title?: string | null,
            isDefault: boolean,
            lat: number,
            long: number,
            instructions?: string | null,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type GetLocationDeliveriesQueryVariables = {
  input: ByIdInput,
};

export type GetLocationDeliveriesQuery = {
  getLocation?:  {
    __typename: "Location",
    deliveries?:  {
      __typename: "DeliveryConnection",
      total: number,
      pageInfo:  {
        __typename: "PageInfo",
        startCursor?: string | null,
        endCursor?: string | null,
        hasPreviousPage?: boolean | null,
        hasNextPage?: boolean | null,
      },
      edges:  Array< {
        __typename: "DeliveryEdge",
        cursor: string,
        node:  {
          __typename: "Delivery",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: DeliveryStatus,
          postalCodes: Array< string >,
          minimumTotal:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
          fee:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        },
      } >,
    } | null,
  } | null,
};

export type GetOfferQueryVariables = {
  input: GetOfferInput,
};

export type GetOfferQuery = {
  offer?:  {
    __typename: "Offer",
    id: string,
    createdAt: string,
    updatedAt: string,
    scheduleId: string,
    status?: OfferStatus | null,
    soldQuantity: number,
    maxQuantity: number,
    price:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        abbreviation: CurrencyAbbreviation,
        symbol: string,
      },
    },
    configurations:  Array< {
      __typename: "ProductConfiguration",
      id: string,
      title: string,
      visible: boolean,
      minSelect: number,
      maxSelect: number,
      options:  Array< {
        __typename: "ProductConfigurationOption",
        id: string,
        description: string,
        visible: boolean,
        price:  {
          __typename: "MonetaryValue",
          amount: number,
          currency:  {
            __typename: "Currency",
            abbreviation: CurrencyAbbreviation,
            symbol: string,
          },
        },
      } >,
    } >,
    product:  {
      __typename: "Product",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: ProductStatus,
      title: string,
      description?: string | null,
      images: Array< string >,
      allergens: Array< string >,
      categories: Array< string >,
      tags: Array< string >,
      price:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      ingredients:  Array< {
        __typename: "Ingredient",
        name?: string | null,
        value?: number | null,
        unit?: IngredientUnit | null,
      } >,
      nutrition?:  {
        __typename: "Nutrition",
        calorie?: number | null,
        carbohydrate?: number | null,
        fat?: number | null,
        protein?: number | null,
      } | null,
    },
  } | null,
};

export type GetOrderQueryVariables = {
  input: GetOrderInput,
};

export type GetOrderQuery = {
  order?:  {
    __typename: "Order",
    id: string,
    createdAt: string,
    updatedAt: string,
    orderType: TransportationType,
    cardLastFour?: string | null,
    cardBrand?: string | null,
    note?: string | null,
    orderItems:  Array< {
      __typename: "OrderItem",
      title: string,
      quantity: number,
      note?: string | null,
      price:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      },
      configurationSelections:  Array< {
        __typename: "OfferConfigurationSelection",
        titleId: string,
        title: string,
        selections:  Array< {
          __typename: "OfferConfigurationOptionSelection",
          optionId: string,
          option: string,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              abbreviation: CurrencyAbbreviation,
              symbol: string,
            },
          },
        } >,
      } >,
    } >,
    schedule:  {
      __typename: "Schedule",
      id: string,
      orderReadyStart: string,
      orderReadyEnd: string,
      orderCutoff: string,
    },
    consumerAddress?:  {
      __typename: "Address",
      id: string,
      createdAt: string,
      updatedAt: string,
      status: AddressStatus,
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
      lat: number,
      long: number,
      isDefault: boolean,
      instructions?: string | null,
    } | null,
    pickupAddress?:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
    } | null,
    cost?:  {
      __typename: "OrderCost",
      subtotal?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      tip?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      estimatedTax?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      deliveryFee?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      couponAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      creditAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
      total?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          abbreviation: CurrencyAbbreviation,
          symbol: string,
        },
      } | null,
    } | null,
  } | null,
};

export type TransitionOrderConsumerCanceledMutationVariables = {
  input: TransitionOrderConsumerCanceledInput,
};

export type TransitionOrderConsumerCanceledMutation = {
  transitionOrderConsumerCanceled?:  {
    __typename: "OrderDefaultPayload",
    order?:  {
      __typename: "Order",
      id: string,
      updatedAt: string,
      orderStatus: OrderStatus,
    } | null,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "OrderDefaultPayload",
    order?:  {
      __typename: "Order",
      id: string,
      note?: string | null,
    } | null,
  } | null,
};

export type ResendOrderReceiptMutationVariables = {
  input: ResendOrderReceiptInput,
};

export type ResendOrderReceiptMutation = {
  resendOrderReceipt?: boolean | null,
};

export type GetPlanQueryVariables = {
  input: GetPlanInput,
};

export type GetPlanQuery = {
  getPlan?:  {
    __typename: "Plan",
    id: string,
    createdAt: string,
    updatedAt: string,
    status: PlanStatus,
    title: string,
    interval: PlanInterval,
    images: Array< string >,
    description?: string | null,
    transportationType: TransportationType,
    chargeDay: DayOfWeek,
    allowSelection: boolean,
    price:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        abbreviation: CurrencyAbbreviation,
        symbol: string,
      },
    },
    pickupAddress?:  {
      __typename: "Address",
      id: string,
      isDefault: boolean,
      createdAt: string,
      updatedAt: string,
      status: AddressStatus,
      lat: number,
      long: number,
      street: string,
      street2?: string | null,
      city: string,
      stateOrProvince?: StateOrProvince | null,
      postalCode: string,
      country: CountryCode,
    } | null,
    fulfillment:  Array< {
      __typename: "PlanFulfillment",
      quantity: number,
      day: DayOfWeek,
      product:  {
        __typename: "Product",
        id: string,
        title: string,
        images: Array< string >,
        description?: string | null,
      },
    } >,
  } | null,
};

export type GetPlanProductsQueryVariables = {
  input: GetPlanInput,
};

export type GetPlanProductsQuery = {
  getPlan?:  {
    __typename: "Plan",
    products?:  {
      __typename: "ProductConnection",
      edges:  Array< {
        __typename: "ProductEdge",
        node:  {
          __typename: "Product",
          id: string,
          createdAt: string,
          updatedAt: string,
          status: ProductStatus,
          title: string,
          description?: string | null,
          images: Array< string >,
          categories: Array< string >,
          tags: Array< string >,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              symbol: string,
              abbreviation: CurrencyAbbreviation,
            },
          },
          ingredients:  Array< {
            __typename: "Ingredient",
            name?: string | null,
            value?: number | null,
            unit?: IngredientUnit | null,
          } >,
          nutrition?:  {
            __typename: "Nutrition",
            calorie?: number | null,
            carbohydrate?: number | null,
            fat?: number | null,
            protein?: number | null,
          } | null,
          configurations:  Array< {
            __typename: "ProductConfiguration",
            id: string,
            title: string,
            visible: boolean,
            minSelect: number,
            maxSelect: number,
            options:  Array< {
              __typename: "ProductConfigurationOption",
              id: string,
              description: string,
              visible: boolean,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
        },
      } >,
    } | null,
  } | null,
};

export type GetRecurringOrderQueryVariables = {
  input: GetRecurringOrderInput,
};

export type GetRecurringOrderQuery = {
  recurringOrder?:  {
    __typename: "RecurringOrder",
    id: string,
    number?: string | null,
    locationId: string,
    createdAt: string,
    updatedAt: string,
    fulfillOn: string,
    transactionType: TransactionType,
    recurringOrderStatus: RecurringOrderStatus,
    orderType: TransportationType,
    instructions?: string | null,
    creditBalanceSnapshot:  {
      __typename: "MonetaryValue",
      amount: number,
      currency:  {
        __typename: "Currency",
        symbol: string,
        abbreviation: CurrencyAbbreviation,
      },
    },
    consumer:  {
      __typename: "Consumer",
      phoneNumber?: string | null,
    },
    consumerAddress?:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
    } | null,
    pickupAddress?:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      postalCode: string,
      country: CountryCode,
      stateOrProvince?: StateOrProvince | null,
    } | null,
    orderItems:  Array< {
      __typename: "RecurringOrderItem",
      title: string,
      quantity: number,
      price:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      },
    } >,
    cost?:  {
      __typename: "OrderCost",
      tip?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      subtotal?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      estimatedTax?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      serviceFee?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      deliveryFee?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      couponAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      creditAmount?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
      total?:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      } | null,
    } | null,
  } | null,
};

export type GetScheduleOffersQueryVariables = {
  input: GetScheduleInput,
  filterOffersInput?: FilterOffersInput | null,
  pagination?: PaginationInput | null,
};

export type GetScheduleOffersQuery = {
  schedule?:  {
    __typename: "Schedule",
    offers?:  {
      __typename: "OfferConnection",
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "OfferEdge",
        node:  {
          __typename: "Offer",
          id: string,
          createdAt: string,
          updatedAt: string,
          scheduleId: string,
          status?: OfferStatus | null,
          soldQuantity: number,
          maxQuantity: number,
          price:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              symbol: string,
              abbreviation: CurrencyAbbreviation,
            },
          },
          configurations:  Array< {
            __typename: "ProductConfiguration",
            id: string,
            title: string,
            visible: boolean,
            minSelect: number,
            maxSelect: number,
            options:  Array< {
              __typename: "ProductConfigurationOption",
              id: string,
              description: string,
              visible: boolean,
              price:  {
                __typename: "MonetaryValue",
                amount: number,
                currency:  {
                  __typename: "Currency",
                  abbreviation: CurrencyAbbreviation,
                  symbol: string,
                },
              },
            } >,
          } >,
          product:  {
            __typename: "Product",
            id: string,
            createdAt: string,
            updatedAt: string,
            status: ProductStatus,
            title: string,
            description?: string | null,
            images: Array< string >,
            allergens: Array< string >,
            categories: Array< string >,
            tags: Array< string >,
            price:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            },
            ingredients:  Array< {
              __typename: "Ingredient",
              name?: string | null,
              value?: number | null,
              unit?: IngredientUnit | null,
            } >,
            nutrition?:  {
              __typename: "Nutrition",
              calorie?: number | null,
              carbohydrate?: number | null,
              fat?: number | null,
              protein?: number | null,
            } | null,
            configurations:  Array< {
              __typename: "ProductConfiguration",
              id: string,
              title: string,
              visible: boolean,
              minSelect: number,
              maxSelect: number,
              options:  Array< {
                __typename: "ProductConfigurationOption",
                id: string,
                description: string,
                visible: boolean,
                price:  {
                  __typename: "MonetaryValue",
                  amount: number,
                  currency:  {
                    __typename: "Currency",
                    abbreviation: CurrencyAbbreviation,
                    symbol: string,
                  },
                },
              } >,
            } >,
            taxExempt: boolean,
          },
        },
      } >,
    } | null,
  } | null,
};

export type GetSubscriptionQueryVariables = {
  input: GetSubscriptionInput,
};

export type GetSubscriptionQuery = {
  subscription?:  {
    __typename: "Subscription",
    id: string,
    status: SubscriptionStatus,
    collection: SubscriptionCollectionStatus,
    createdAt: string,
    updatedAt: string,
    currentPeriodStart: string,
    currentPeriodEnd: string,
    card?:  {
      __typename: "Card",
      lastFour: string,
      brand: string,
      expMonth: number,
      expYear: number,
    } | null,
    plan:  {
      __typename: "Plan",
      title: string,
      interval: PlanInterval,
      price:  {
        __typename: "MonetaryValue",
        amount: number,
        currency:  {
          __typename: "Currency",
          symbol: string,
          abbreviation: CurrencyAbbreviation,
        },
      },
    },
  } | null,
};

export type getSubscriptionInvoicesQueryVariables = {
  input: GetSubscriptionInput,
  pagination?: PaginationInput | null,
};

export type getSubscriptionInvoicesQuery = {
  subscription?:  {
    __typename: "Subscription",
    subscriptionInvoices?:  {
      __typename: "SubscriptionInvoiceConnection",
      pageInfo:  {
        __typename: "PageInfo",
        hasNextPage?: boolean | null,
        hasPreviousPage?: boolean | null,
        startCursor?: string | null,
        endCursor?: string | null,
      },
      edges:  Array< {
        __typename: "SubscriptionInvoiceEdge",
        node:  {
          __typename: "SubscriptionInvoice",
          id: string,
          createdAt: string,
          invoiceStatus: InvoiceStatus,
          paymentStatus?: PaymentStatus | null,
          number?: string | null,
          cardLastFour?: string | null,
          cardBrand?: string | null,
          retryAttemptCount?: number | null,
          nextPaymentAttempt?: string | null,
          creditTotal?:  {
            __typename: "MonetaryValue",
            amount: number,
            currency:  {
              __typename: "Currency",
              symbol: string,
              abbreviation: CurrencyAbbreviation,
            },
          } | null,
          cost?:  {
            __typename: "SubscriptionInvoiceCost",
            total?:  {
              __typename: "MonetaryValue",
              amount: number,
              currency:  {
                __typename: "Currency",
                symbol: string,
                abbreviation: CurrencyAbbreviation,
              },
            } | null,
          } | null,
        },
      } >,
    } | null,
  } | null,
};

export type CreateSubscriptionMutationVariables = {
  input: CreateSubscriptionInput,
};

export type CreateSubscriptionMutation = {
  createSubscription?:  {
    __typename: "SubscriptionDefaultPayload",
    subscription?:  {
      __typename: "Subscription",
      id: string,
      status: SubscriptionStatus,
      collection: SubscriptionCollectionStatus,
    } | null,
  } | null,
};

export type CancelSubscriptionMutationVariables = {
  input: CancelSubscriptionInput,
};

export type CancelSubscriptionMutation = {
  cancelSubscription?:  {
    __typename: "SubscriptionDefaultPayload",
    subscription?:  {
      __typename: "Subscription",
      id: string,
      status: SubscriptionStatus,
    } | null,
  } | null,
};

export type UpdateSubscriptionMutationVariables = {
  input: UpdateSubscriptionInput,
};

export type UpdateSubscriptionMutation = {
  updateSubscription?:  {
    __typename: "SubscriptionDefaultPayload",
    subscription?:  {
      __typename: "Subscription",
      id: string,
      status: SubscriptionStatus,
      collection: SubscriptionCollectionStatus,
    } | null,
  } | null,
};

export type UpdateSubscriptionFulfillmentMutationVariables = {
  input: UpdateSubscriptionFulfillmentInput,
};

export type UpdateSubscriptionFulfillmentMutation = {
  updateSubscriptionFulfillment?:  {
    __typename: "SubscriptionDefaultPayload",
    subscription?:  {
      __typename: "Subscription",
      id: string,
      status: SubscriptionStatus,
      createdAt: string,
      updatedAt: string,
      collection: SubscriptionCollectionStatus,
      currentPeriodStart: string,
      currentPeriodEnd: string,
      plan:  {
        __typename: "Plan",
        title: string,
      },
      fulfillment:  Array< {
        __typename: "PlanFulfillment",
        quantity: number,
        day: DayOfWeek,
        product:  {
          __typename: "Product",
          id: string,
          title: string,
          images: Array< string >,
          description?: string | null,
        },
      } >,
    } | null,
  } | null,
};

export type CalculateTaxQueryVariables = {
  input: CalculateTaxInput,
};

export type CalculateTaxQuery = {
  calculateTax:  {
    __typename: "MonetaryValue",
    amount: number,
    currency:  {
      __typename: "Currency",
      symbol: string,
      abbreviation: CurrencyAbbreviation,
    },
  },
};
